import { useAutoAnimate } from "@formkit/auto-animate/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../config/config";

import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Link,
  Select,
} from "@chakra-ui/react";
import Card from "../Card/Card";
import ContentSide from "../ContentSide/ContentSide";
import { FaChevronRight } from "react-icons/fa";
import dayjs from "dayjs";

const introText = {
  atividadeselecionada: {
    title: "Atividade Selecionada",
    text: "Tem de selecionar uma atividade primeiro antes de prosseguir",
  },
  borboletáriojoãopedrocardosodaconceição: {
    title: "BORBOLETÁRIO",
    text: `O Borboletário, situado no Parque Urbano da Quinta de Rana, é um espaço dedicado à criação de borboletas, onde é possível observar as diferentes fases do seu ciclo de vida: ovos, lagartas, crisálidas e borboletas. 
<br/><br/>A estrutura do borboletário é única e remete visualmente para a imagem de um casulo em fase de metamorfose. No seu interior encontra-se um jardim, com espécies da nossa flora, onde é possível observar borboletas a voar livremente. Integra ainda um laboratório, cuja função é a criação dos ovos, das lagartas e das crisálidas.
<br/><br/>Aqui dá-se a conhecer as espécies de borboletas que existem na região de Cascais, ensina-se a biologia deste grupo de insetos e sua interligação com as plantas, e proporciona-se aos visitantes uma experiência de observação e aprendizagem que contribui para o despertar e interesse da Conservação da Natureza e Biodiversidade.`,
  },
  centrodeinterpretaçãoambientaldapedradosal: {
    title: "CENTRO DE INTERPRETAÇÃO AMBIENTAL DA PEDRA DO SAL",
    text: `O Centro de Interpretação Ambiental da Pedra do Sal é um espaço vocacionado para divulgar valores do Património Natural, com uma localização invejável em São Pedro do Estoril, que funciona como uma montra virada para a linha de costa e para o mar.
<br/><br/>O espaço vocacionado para a educação ambiental beneficia da diversidade biofísica que se gera do encontro da terra com o mar e da riqueza cénica da zona envolvente, proporcionando óptimas condições para o visitante desfrutar das características biofísicas e paisagísticas singulares da "Ponta do Sal". Está situado junto à Área Marinha Protegida das Avencas (AMPA) – que apresenta características ímpares do ponto de vista biológico, geológico e geofísico, e está integrado desde 2007 na Grande Rota Europeia dos Pedestrianistas – um percurso pedestre transeuropeu que atravessa a Europa e que incluiu S. Pedro do Estoril no mapa dos locais de Turismo da Natureza da Costa de Cascais.
<br/><br/>O Centro é considerado o primeiro "Zero Energy building" público em Portugal, de acordo com a ADENE - Agência de Energia. Esta classificação traduz-se na diminuição progressiva da “Pegada Ecológica” deste edifício, aproximando-o da autossuficiência energética.`,
  },
  dunadacresmina: {
    title: "DUNA DA CRESMINA",
    text: `As dunas do Guincho-Cresmina são uma área de 66 ha que pertence ao Complexo Guincho-Oitavos localizado no Parque Natural de Sintra-Cascais. Este sistema dunar é bastante particular pois a areia proveniente das praias do Guincho e da Cresmina retorna ao mar mais a sul – entre Oitavos e Guia, após migrar sobre a plataforma rochosa aplanada do Cabo Raso. Designa-se por corredor eólico dunar Cresmina-Oitavos.
<br/><br/>O Núcleo de Interpretação da Duna da Cresmina dispõe de uma exposição fixa sobre o sistema dunar e serve como ponto de partida para momentos de descoberta, levando os visitantes a explorarem e sentirem, através de um percurso de passadiços, as dunas do Guincho e as suas especificidades.`,
  },
  quintadopisão: {
    title: "QUINTA DO PISÃO",
    text: `A Quinta do Pisão é um Parque de Natureza, com 380 hectares, situado no Parque Natural de Sintra-Cascais, na transição entre a zona urbana de Cascais e a Serra de Sintra, o que se traduz num mosaico de vegetação extremamente rico e numa diversidade de habitats para a fauna silvestre.
<br/><br/>
Neste parque único há espaço para experiências na natureza, história, atividade agrícola e conservação da natureza. Pode colher legumes na horta biológica, visitar o Trilho do Cuquedo e conhecer a fauna autóctone que o transporta para o passado rural da Quinta - burros de Miranda, ovelhas campaniças, cabras-serranas e corços. Conheça também o projeto Natureza para Todos, que pretende transformar este espaço verde num local mais inclusivo para todos.   `,
  },
  pedraamarelacampobase: {
    title: "PEDRA AMARELA CAMPO BASE",
    text: `O Pedra Amarela Campo Base situa-se na encosta Sul da Serra de Sintra sobre o concelho de Cascais e é uma plataforma para a realização de atividades aventura e de turismo de natureza. O Campo Base e as suas atividades são concebidos segundo o princípio da sustentabilidade e com a minimização dos impactos ambientais, tendo como objetivo uma pegada ecológica mínima.
<br/><br/>O Campo Base está aberto a todos os utilizadores que pretendam beneficiar das suas instalações e localização, proporcionando atividades de ar livre e exploração na Natureza.`,
  },
  áreamarinhaprotegidadasavencas: {
    title: "ÁREA MARINHA PROTEGIDA DAS AVENCAS",
    text: `A Área Marinha Protegida das Avencas, é uma área dominada pela presença de arribas, com características paisagísticas de grande interesse, dado aos estratos rochosos que se desenvolvem como uma escadaria natural, permitindo a observação da zona costeira.
<br/><br/>As plataformas rochosas e o areal foram classificados em 1998 como Zona de Interesse Biofísico pela sua elevada importância ecológica e atualmente ganhou a classificação de Área Marinha Protegida das Avencas.
<br/><br/>Tem sido, nos últimos anos, galardoada com a Bandeira Azul (Símbolo de Qualidade).
<br/><br/>Esta área é constituída por uma praia, “Praia das Avencas”, que deve o seu nome a uma planta – a Avenca – com longa tradição de uso medicinal em Portugal.`,
  },
  parquenaturalsintracascais: {
    title: "PARQUE NATURAL SINTRA-CASCAIS",
    text: `O Parque Natural de Sintra-Cascais estende-se do limite norte do concelho de Sintra, junto à foz do rio Falcão, para sul até à Cidadela de Cascais. A serra de Sintra, com 528 m de altitude máxima, é o seu elemento dominante, a que se juntam, a completar a paisagem, uma extensa área rural e uma belíssima faixa costeira. Juntos constituem uma zona de grande interesse ecológico e cultural, devido às suas características geomorfológicas, florísticas e paisagísticas.
<br/><br/>
Criado em Criado em 1994 enquanto Parque Natural, pelo Decreto-lei n.º 8/94 de 11 de março, o Parque Natural Sintra-Cascais tem 14,583 hectares e abriga mais de 200 espécies de vertebrados:  33 de mamíferos, mais de 160 de aves, 12 de anfíbios, 20 de répteis e 9 de peixes de água doce.
<br/><br/>
Em Cascais inclui as freguesias de Cascais e Alcabideche.`,
  },
};

const NoActivity = () => {
  const [activities, setActivities] = useState([]);
  const [tags, setTags] = useState([]);
  const [types, setTypes] = useState([]);
  const [search, setSearch] = useState("");
  const [location, setLocation] = useState("");
  const [type, setType] = useState("");
  const [parent, enableAnimations] = useAutoAnimate(/* optional config */);

  //get query param for location and update state
  useEffect(() => {
    if (typeof window === "undefined") return;
    const urlParams = new URLSearchParams(window?.location?.search);
    const location = urlParams.get("location");
    if (location)
      setLocation(
        location.trim().replace(/ /g, "").replace(/-/g, "").toLowerCase()
      );
  }, []);
  /**
   * Get Schools
   */
  useEffect(() => {
    async function fetchData() {
      try {
        const activitieslData = await axios.post(`booking/activities`);

        if (!activitieslData.data) throw new Error("no data");
        setActivities(activitieslData.data);
        //lets make a list of all tags
        let tagsRaw = [];
        let typesRaw = [];
        activitieslData.data.forEach((activity) => {
          tagsRaw.push(activity?.tag);
          typesRaw.push(activity?.type?.name);
        });
        //make it unique
        tagsRaw = [...new Set(tagsRaw)];
        typesRaw = [...new Set(typesRaw)];

        setTags(tagsRaw);
        setTypes(typesRaw);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  const filterActivities = (act) => {
    try {
      let composedSearch = act;
      //lets remove groups
      composedSearch = composedSearch.filter(
        (activity) => !activity.group && !activity.parentActivity
      );

      if (search && search !== "") {
        composedSearch = composedSearch.filter((activity) =>
          activity.title.toLowerCase().includes(search.toLowerCase())
        );
      }
      if (location && location !== "") {
        composedSearch = composedSearch.filter((activity) =>
          activity.tag
            ?.replace(/ /g, "")
            ?.replace(/-/g, "")
            .toLowerCase()
            .includes(location?.replace(/ /g, "").toLowerCase())
        );
      }
      if (type && type !== "") {
        composedSearch = composedSearch.filter(
          (activity) => activity?.type?.name.toLowerCase() == type.toLowerCase()
        );
      }
      return (
        composedSearch?.sort(
          (a, b) => new Date(a.endDate) - new Date(b.endDate)
        ) || []
      );
    } catch (error) {
      return act;
    }
  };
  // let thisLocation = location
  //   .trim()
  //   .replace(/ /g, "")
  //   .replace(/-/g, "")
  //   .toLowerCase();
  console.log(location);
  return (
    <ContentSide image={null}>
      <Box width={"100%"} padding={"0 2em"}>
        <Box mb={"3em"} maxW={"80%"}>
          <Box
            lineHeight={"1"}
            fontWeight={"bold"}
            color={"#599331"}
            fontSize={"2.5em"}
            mb={".5em"}
            textTransform={"titlecase"}
          >
            {introText[location]?.title || "Atividades na Natureza"}
          </Box>
          <p
            dangerouslySetInnerHTML={{
              __html:
                introText[location]?.text ||
                "Tem de selecionar uma atividade primeiro antes de prosseguir",
            }}
          />
        </Box>
        <Flex mb={"2em"} justifyContent={"flex-end"}>
          <HStack w={"70%"} gap={2}>
            <Input
              placeholder="Procurar Atividade"
              onChange={(e) => setSearch(e.target.value)}
            />
            <Select
              background={"#fff"}
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            >
              <option value="">Localização</option>
              {tags
                ?.sort((a, b) => a.localeCompare(b))
                .map((tag, index) => (
                  <option
                    key={index}
                    value={tag
                      ?.trim()
                      .replace(/ /g, "")
                      .replace(/-/g, "")
                      .toLowerCase()}
                  >
                    {tag}
                  </option>
                ))}
            </Select>
            <Select
              background={"#fff"}
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="">Tipo</option>
              {types.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </Select>
          </HStack>
        </Flex>
        <Flex m={"5vh 0"} gap={"2em"} flexWrap={"wrap"} w={"100%"} ref={parent}>
          {filterActivities(activities)?.map((activity, index) => (
            <Box key={index} w={{ base: "100%", sm: "45%", lg: "30%" }}>
              {activity.image && activity.image !== "" && (
                <Box
                  w={"100%"}
                  h={"200px"}
                  bgImage={`url(${activity.image})`}
                  bgSize={"cover"}
                  bgPos={"center"}
                  borderRadius={"10px 10px 0 0"}
                  boxShadow={"0 0 10px 0 #00000050"}
                />
              )}
              <Box
                fontSize={"1em"}
                fontWeight={"bold"}
                padding={".6em 1em"}
                background={"#1a3323"}
                color={"#fff"}
                borderRadius={"10px 10px 0 0"}
                mt={"-10px"}
              >
                <Flex
                  w={"100%"}
                  gap={"2"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box fontSize={".9em"}>{activity.title}</Box>
                  {activity.price &&
                    activity.price !== "" &&
                    activity.price !== 0 && (
                      <Box
                        borderRadius={"6px"}
                        background={"#ffffff50"}
                        padding={".2em .4em"}
                        fontSize="md"
                        colorScheme="orange"
                      >
                        {activity.price}€
                      </Box>
                    )}
                </Flex>
              </Box>
              <Card p={"1em 1.5em"} borderRadius={"0 0 10px 10px"}>
                <Box
                  fontSize={".8em"}
                  mb={"1em"}
                  fontWeight={"bold"}
                  lineHeight={1.2}
                >
                  de {dayjs(activity.date).format("DD/MM/YYYY")}, até{" "}
                  {dayjs(activity.endDate).format("DD/MM/YYYY")}
                </Box>
                <Box
                  fontSize={".8em"}
                  lineHeight={1.2}
                  overflow={"hidden"}
                  height={"3.8em"}
                  _hover={{ height: "auto" }}
                  dangerouslySetInnerHTML={{
                    __html: activity?.description?.replace(/\n/, "<br/>") || "",
                  }}
                ></Box>
                {activity?.type?.name && (
                  <Badge
                    borderRadius={"6px"}
                    mt={"1em"}
                    size="xs"
                    colorScheme="orange"
                    padding={".2em .4em"}
                  >
                    {activity?.type?.name}
                  </Badge>
                )}

                <Box
                  fontSize={".8em"}
                  fontWeight={"bold"}
                  mt={"1em"}
                  lineHeight={1.2}
                >
                  {activity.tag}
                </Box>

                <Flex w={"100%"} justifyContent={"center"} mb={"1em"}>
                  <Link
                    href={`${config.publicURL}/${activity._id}${window?.location?.search}`}
                    w={"100%"}
                    display={"inline-block"}
                  >
                    <Button
                      colorScheme={"green"}
                      variant="outline"
                      size={"sm"}
                      mt={"1em"}
                      w={"100%"}
                      display={"inline-block"}
                      rightIcon={<FaChevronRight fontSize=".8em" />}
                    >
                      Escolher Atividade
                    </Button>
                  </Link>
                </Flex>
              </Card>
            </Box>
          ))}
        </Flex>
      </Box>
    </ContentSide>
  );
};

export default NoActivity;
