import { useAutoAnimate } from "@formkit/auto-animate/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../config/config";

import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Link,
  Select,
} from "@chakra-ui/react";
import Card from "../Card/Card";
import ContentSide from "../ContentSide/ContentSide";
import { FaChevronRight } from "react-icons/fa";
import dayjs from "dayjs";

const GroupActivity = ({ isGroup, activity }) => {
  const [activities, setActivities] = useState(isGroup || []);

  const [tags, setTags] = useState([]);
  const [types, setTypes] = useState([]);
  const [search, setSearch] = useState("");
  const [location, setLocation] = useState("");
  const [type, setType] = useState("");
  const [parent, enableAnimations] = useAutoAnimate(/* optional config */);

  //get query param for location and update state
  useEffect(() => {
    if (typeof window === "undefined") return;
    const urlParams = new URLSearchParams(window?.location?.search);
    const location = urlParams.get("location");
    if (location)
      setLocation(
        location.replace(/-/g, " ").replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        })
      );
  }, []);

  // /**
  //  * Get Schools
  //  */
  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const activitieslData = await axios.post(`booking/activities`);

  //       if (!activitieslData.data) throw new Error("no data");
  //       setActivities(activitieslData.data);
  //       //lets make a list of all tags
  //       let tagsRaw = [];
  //       let typesRaw = [];
  //       activitieslData.data.forEach((activity) => {
  //         tagsRaw.push(activity?.tag);
  //         typesRaw.push(activity?.type?.name);
  //       });
  //       //make it unique
  //       tagsRaw = [...new Set(tagsRaw)];
  //       typesRaw = [...new Set(typesRaw)];

  //       setTags(tagsRaw);
  //       setTypes(typesRaw);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   fetchData();
  // }, []);

  const filterActivities = (act) => {
    try {
      let composedSearch = act;

      if (search && search !== "") {
        composedSearch = composedSearch.filter((activity) =>
          activity.title.toLowerCase().includes(search.toLowerCase())
        );
      }

      //sort by end date
      return (
        composedSearch?.sort(
          (a, b) => new Date(a.endDate) - new Date(b.endDate)
        ) || []
      );
    } catch (error) {
      return act;
    }
  };

  return (
    <ContentSide altImage={activity?.image} image={null}>
      <Box width={"100%"} padding={"0 2em"}>
        <Box mb={"2em"} maxW={"700px"}>
          <Box
            lineHeight={"1"}
            fontWeight={"bold"}
            color={"#599331"}
            fontSize={"2.5em"}
            mb={".5em"}
          >
            {activity?.title || "Atividades na Natureza"}
          </Box>
          <p
            dangerouslySetInnerHTML={{
              __html:
                activity?.description?.replace(/\n/, "<br/>") ||
                "As Atividades de Natureza são um conjunto de atividades e experiências na natureza que envolvem o visitante mais preocupado e atento às questões ambientais e aos valores naturais. Permitem, assim, usufruir do território de forma responsável e participando ativamente na sua conservação e valorização.Atividades na Natureza",
            }}
          ></p>
        </Box>
        <Flex mb={"2em"} justifyContent={"flex-end"}>
          <HStack w={"30%"} gap={2}>
            <Input
              placeholder="Procurar Atividade"
              onChange={(e) => setSearch(e.target.value)}
            />
          </HStack>
        </Flex>
        <Flex m={"5vh 0"} gap={"2em"} flexWrap={"wrap"} w={"100%"} ref={parent}>
          {filterActivities(activities)?.map((activity, index) => (
            <Box key={index} w={{ base: "100%", sm: "45%", lg: "30%" }}>
              {activity.image && activity.image !== "" && (
                <Box
                  w={"100%"}
                  h={"200px"}
                  bgImage={`url(${activity.image})`}
                  bgSize={"cover"}
                  bgPos={"center"}
                  borderRadius={"10px 10px 0 0"}
                  boxShadow={"0 0 10px 0 #00000050"}
                />
              )}
              <Box
                fontSize={"1em"}
                fontWeight={"bold"}
                padding={".6em 1em"}
                background={"#1a3323"}
                color={"#fff"}
                borderRadius={"10px 10px 0 0"}
                mt={"-10px"}
              >
                <Flex
                  w={"100%"}
                  gap={"2"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box fontSize={".9em"}>{activity.title}</Box>
                  {activity.price &&
                    activity.price !== "" &&
                    activity.price !== 0 && (
                      <Box
                        borderRadius={"6px"}
                        background={"#ffffff50"}
                        padding={".2em .4em"}
                        fontSize="md"
                        colorScheme="orange"
                      >
                        {activity.price}€
                      </Box>
                    )}
                </Flex>
              </Box>
              <Card p={"1em 1.5em"} borderRadius={"0 0 10px 10px"}>
                <Box
                  fontSize={".8em"}
                  mb={"1em"}
                  fontWeight={"bold"}
                  lineHeight={1.2}
                >
                  de {dayjs(activity.date).format("DD/MM/YYYY")}, até{" "}
                  {dayjs(activity.endDate).format("DD/MM/YYYY")}
                </Box>
                <Box
                  fontSize={".8em"}
                  lineHeight={1.2}
                  overflow={"hidden"}
                  height={"3.8em"}
                  _hover={{ height: "auto" }}
                  dangerouslySetInnerHTML={{
                    __html: activity?.description?.replace(/\n/, "<br/>") || "",
                  }}
                />
                {activity?.type?.name && (
                  <Badge
                    borderRadius={"6px"}
                    mt={"1em"}
                    size="xs"
                    colorScheme="orange"
                    padding={".2em .4em"}
                  >
                    {activity?.type?.name}
                  </Badge>
                )}
                <Box
                  fontSize={".8em"}
                  fontWeight={"bold"}
                  mt={"1em"}
                  lineHeight={1.2}
                >
                  {activity.tag}
                </Box>

                <Flex w={"100%"} justifyContent={"center"} mb={"1em"}>
                  <Link
                    href={`${config?.websiteURL}/${activity._id}${window?.location?.search}`}
                    w={"100%"}
                    display={"inline-block"}
                  >
                    <Button
                      colorScheme={"green"}
                      variant="outline"
                      size={"sm"}
                      mt={"1em"}
                      w={"100%"}
                      display={"inline-block"}
                      rightIcon={<FaChevronRight fontSize=".8em" />}
                    >
                      Escolher Atividade
                    </Button>
                  </Link>
                </Flex>
              </Card>
            </Box>
          ))}
        </Flex>
      </Box>
    </ContentSide>
  );
};

export default GroupActivity;
